<template>
  <SourcesEdit></SourcesEdit>
</template>

<script>
import SourcesEdit from "@/components/newSources/SourcesEdit";
export default {
  name: "Edit",
  components: { SourcesEdit }
};
</script>

<style scoped></style>
